import request from '../utils/request'
//
export function getCertDirect(registerCertNumber){
    return request({
      url: `/web/register/small/certDirect`,
      data: {registerCertNumber}
    })
  }
  export function smsMessage(mobile){
    return request({
      url: `/web/message/smsMessage`,
      data: {mobile}
    })
  }
  export function getRegisterCertUrl(data){
    return request({
      url: `/web/register/small/getRegisterCertUrl`,
      data
    })
  }
  //获取验证滑块
  export function gen(data){
    return request({
      url: `/web/captcha/gen`,
      data
    })
  }

  
//获取验证图片  以及token
export function reqGet(data) {
  return request({
      url: '/captcha/generate-slider',
      method: 'get',
      data
  })
}

//滑动或者点选验证
export function reqCheck(data) {
  return request({
      url: '/captcha/check',
      method: 'post',
      data
  })
}
//验证成功获取登记证书查询结果
export function getCertResult(data) {
  return request({
      url: `/register/small/certDirect?registernumber=${data.registernumber}&captchaVerification=${data.captchaVerification}`,
      method: 'get',
  })
}


//验证成功获取存证证书查询结果
export function getJuResult(data) {
  return request({
    url: `/judicial/qrCode?judicial=${data.judicial}&captchaVerification=${data.captchaVerification}`,
    method: 'get',
  })
}
