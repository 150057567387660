import axios from 'axios'
import {HOST,ERROR_CODE} from './contant'
import {tansParams} from './index'
//import cache from './cache'
import { Notify } from '@nutui/nutui-react';
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const [get,post,put] = ['get','post','put']
// 限制存放数据5M
const limitSize = 5 * 1024 * 1024; 
// 重复提交限制时间
//const interval = 1000;   
const service = axios.create({
  baseURL: HOST,
  // 超时
 // timeout: interval
})
                      
// request拦截器
service.interceptors.request.use(config => {
  // 是否需要防止数据重复提交
  const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  const method = config?.method?config.method.toLowerCase():get
  
  if (method === get && config.data) {
    let url = config.url + '?' + tansParams(config.data);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (method === post || method === put)) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const requestSize = Object.keys(JSON.stringify(requestObj)).length; // 请求数据大小
    
    if (requestSize >= limitSize) {
      console.warn(`[${config.url}]: ` + `请求数据大小超出允许的${limitSize/1024/1024}M限制，无法进行防重复提交验证。`)
      return config;
    }
    // const sessionObj = cache.session.getJSON('sessionObj')
    // if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
    //   cache.session.setJSON('sessionObj', requestObj)
    // } else {
    //     const {url:s_url,data:s_data,time:s_time} = sessionObj            
     
    //   if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
    //     return Promise.reject()
    //   } else {
    //     cache.session.setJSON('sessionObj', requestObj)
    //   }
    // }
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    // 未设置状态码则默认成功状态
    const code = res.data.code || 200;
    // 获取错误信息
    const msg = ERROR_CODE[code] || res.data.msg || ERROR_CODE['default']
    // 二进制数据则直接返回
    if (res.request.responseType ===  'blob' || res.request.responseType ===  'arraybuffer') {
      return res.data
    }
    if(code !== 200){
        Notify.warn(msg)
        return Promise.reject(res.data)
    }else {
        return res.data
      }
  },
  error => {
    let { message } = error;
    if (message == "Network Error") {
      message = "后端接口连接异常";
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    Notify.warn(message)
    return Promise.reject(error)
  }
)


export default service
