import request from '../../../utils/request'
//获取验证图片  以及token
export function reqGet(data) {
    return request({
        url: '/captcha/generate-slider',
        method: 'get',
        data
    })
  }
  
  //滑动或者点选验证
  export function reqCheck(data) {
    return request({
        url: '/captcha/check',
        method: 'post',
        data
    })
  }
  
  //验证成功获取登记证书查询结果
