import {maskPhoneNumber} from "./index"
//export const HOST = "https://testapi.gzbqdj.com/"; //正式环境
//export const HOST = "https://testapi.gzbqdj.com/"; //测试环境
//export const HOST = "http://192.168.2.15:8204/"; 
export const HOST = "/basic-api/"//正式环境
export const LOG_URL = "https://cdn.gzbqdj.com/common/4361dfa4c097b8f653260515b7bcee1.png"
export const ERROR_CODE = {
    '401': '认证失败，无法访问系统资源',
    '403': '当前操作没有权限',
    '404': '访问资源不存在',
    'default': '系统未知错误，请反馈给管理员'
} 
export const LOADING_TXT = '加载中~'
export const LOADING_BOOK = '证书加载中~'
export const GET_BOOK = '查看登记证书'
export const INPUT_CODE = '输入短信验证码'
export const SEND_MSG = "短信发送中"
export const SYS_ERROR = "系统错误"
export const TEXT_BNT = {
    "0":'发送验证码',
    "1":'发送中',
    "2":'重新发送',
    "3":"已发送"
}

export const codePhone=(t,p)=>`${t}${maskPhoneNumber(p)}`