import React, { useEffect, useState } from "react";
import "./App.css";
import { Toast } from "@nutui/nutui-react";
import { Loading } from '@nutui/icons-react'

import "@nutui/nutui-react/dist/style.css";
import axios from "axios";
import VerifySlide from "./components/verify/verifySlide";
import "./components/verify/index.css";
import { getJuResult } from "./api/index";
import "./tac/css/tac.css";
import { Overlay } from "@nutui/nutui-react";

function JudicialApp(props) {
  const [data, setData] = useState("");
  const [isShow, setIsShow] = useState(false);
  const duringToast = (msg) => {
    Toast.loading(msg, { duration: 2, cover: true });
  };
   const [visible, setVisible] = useState(true)
     const succeess = (res)=>{
       setVisible(false)
       const arr = props.url.split("?");
       //const data = encodeURIComponent(arr[1].substring(9));
       const data = {
        judicial: encodeURIComponent(arr[1].substring(9)),
        captchaVerification: encodeURIComponent(res.captchaVerification),
       }
       getJuResult(data).then((r) => {
          setData(r.data)
        });
     }


  //日期格式化
  const formDate = (timestamp) => {
    var date = new Date(timestamp);
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D = date.getDate() + " ";
    var h =
      (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
    var m =
      (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      ":";
    var s =
      date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    return Y + M + D + h + m + s;
  };
  return (
    <div className="App">
      <Overlay visible={visible}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}>
          <div style={{ position: "relative" }}>
            <VerifySlide succeess={succeess} />
          </div>
        </div>
      </Overlay>
      {!visible?
        <span> {data? (
        <div className="listWrap">
          <div className="title">核验结果</div>
          <img src={require("./img/success.png")} style={{ width: 380 }} />
          <div className="content">
            <div className="hash">
              <div style={{ color: "#06c", margin: "5px 0" }}>
                统一证据编号：
              </div>
              <div>{data.txHash}</div>
            </div>
            <div className="hash">
              <div style={{ color: "#06c", margin: "5px 0" }}>
                所在区块hash：
              </div>
              <div>{data.hash}</div>
            </div>
            <div className="hash">
              <div style={{ color: "#06c", margin: "5px 0" }}>存证时间：</div>
              <div>
                {data.blockTimestamp ? formDate(data.blockTimestamp) : "--"}
              </div>
            </div>
            <div className="hash">
              <div style={{ color: "#06c", margin: "5px 0" }}>存证类型：</div>
              <div>{data.blockType === 1 ? "文件存证" : "哈希存证"}</div>
            </div>
            <div className="hash">
              <div style={{ color: "#06c", margin: "5px 0" }}>块高：</div>
              <div>{data.blockHeight}</div>
            </div>
          </div>
        </div>
        ) : null}
         {!data ? (
        <div className="listWrap">
          <div className="title">核验结果</div>
          <img
            src={require("./img/err.png")}
            style={{ width: 240, margin: "0 auto" }}
          />
        </div>
      ) : null}
        </span> : <div style={{marginTop:150}}><Loading width={20} />证书核验中...</div>
      }
     
     
    </div>
  );
}

export default JudicialApp;
